export default class ViewersPublic {
  value: boolean;

  constructor(
    value?: boolean
  ) {
    this.value = value || false;
  }

  static clone(source: ViewersPublic): ViewersPublic {
    return new ViewersPublic(source.value);
  }
}
