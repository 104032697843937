export default class FileName {
  value: string;

  constructor(value?: string) {
    this.value = (value === undefined ? '' : value);
  }
  static clone(source: FileName): FileName {
    return new FileName(source.value);
  }
}
