import PostId from '../PostId';
import PostLimitDate from './PostLimitDate';
import PostMessageBody from './PostMessageBody';

/**
 * 情報投稿
 */
export default class PostMessage {
  postId: PostId;
  message: PostMessageBody;
  limitDate: PostLimitDate;

  constructor(
    postId?: PostId,
    message?: PostMessageBody,
    limitDate?: PostLimitDate
  ) {
    this.postId = (postId === undefined ? new PostId() : postId);
    this.message = (message === undefined ? new PostMessageBody() : message);
    this.limitDate = (limitDate === undefined ? new PostLimitDate() : limitDate);
  }
  public static clone(source:PostMessage):PostMessage {
    const result = new PostMessage();
    result.postId = PostId.clone(source.postId);
    result.message = PostMessageBody.clone(source.message);
    result.limitDate = PostLimitDate.clone(source.limitDate);
    return result;
  }
}
