import TypeDateTime from '../type/TypeDateTime';
import UserID from '../user/UserID';
import UserName from '../user/UserName';
import DataType from './DataType';
import PostId from './PostId';
import PostMessage from './message/PostMessage';
import PostTitle from './PostTitle';
import PostStatus from './PostStatus';
import ViewersPublic from './ViewersPublic';
import Priv from '../user/Priv';

export default class PostBody {
  /** 投稿ID */
  postId: PostId;

  /** ステータス */
  status: PostStatus;

  /** 投稿区分 */
  dataType: DataType;

  /** タイトル */
  postTitle: PostTitle;

  /** 閲覧者公開 */
  viewersPublic: ViewersPublic;

  /** 投稿者ID */
  registerId: UserID;

  /** 投稿者名 */
  registerName: UserName;

  /** 投稿者権限 */
  registerPriv: Priv;

  /** 更新日時 */
  updateDate: TypeDateTime;

  /** 公開日時 */
  openDate: TypeDateTime;

  /** 情報投稿 */
  message: PostMessage;

  constructor(
    postId?: PostId,
    status?: PostStatus,
    dataType?: DataType,
    postTitle?: PostTitle,
    viewersPublic?: ViewersPublic,
    registerId?: UserID,
    registerName?: UserName,
    registerPriv?: Priv,
    updateDate?: TypeDateTime,
    openDate?: TypeDateTime,
    message?: PostMessage
  ) {
    this.postId = (postId === undefined ? new PostId() : postId);
    this.status = (status === undefined ? new PostStatus() : status);
    this.dataType = (dataType === undefined ? new DataType() : dataType);
    this.postTitle = (postTitle === undefined ? new PostTitle() : postTitle);
    this.viewersPublic = viewersPublic || new ViewersPublic();
    this.registerId = (registerId === undefined ? new UserID() : registerId);
    this.registerName = (registerName === undefined ? new UserName() : registerName);
    this.registerPriv = (registerPriv === undefined ? new Priv() : registerPriv);
    this.updateDate = (updateDate === undefined ? new TypeDateTime() : updateDate);
    this.openDate = (openDate === undefined ? new TypeDateTime() : openDate);
    this.message = (message === undefined ? new PostMessage() : message);
  }

  public static clone(source:PostBody):PostBody {
    const result = new PostBody();
    result.postId = PostId.clone(source.postId);
    result.status = PostStatus.clone(source.status);
    result.dataType = DataType.clone(source.dataType);
    result.postTitle = PostTitle.clone(source.postTitle);
    result.viewersPublic = ViewersPublic.clone(source.viewersPublic);
    result.registerId = UserID.clone(source.registerId);
    result.registerName = UserName.clone(source.registerName);
    result.registerPriv = Priv.clone(source.registerPriv);
    result.updateDate = TypeDateTime.clone(source.updateDate);
    result.openDate = TypeDateTime.clone(source.openDate);
    result.message = PostMessage.clone(source.message);
    return result;
  }
}
