
import { Vue, Component } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router';
import PostBody from '@/model/posts/PostBody';
import BackButton from '@/components/Parts/BackButton.vue';
import RequireMark from '@/components/Parts/RequireMark.vue';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import InformationApi from '@/module/api/InformationApi';
import { DRAFT, PUBLIC } from '@/model/posts/PostStatus';
import { INFO } from '@/model/posts/DataType';
import UploadFileContainer from '@/model/type/UploadFileContainer';
import FileUpload from '@/components/Parts/FileUpload.vue';
import FileInfo from '@/model/type/FileInfo';
import UserApi from '@/module/api/UserApi';
import PostViewerSelector from '@/views/post/PostViewerSelector.vue';
import MessageShowBox from '@/components/post/MessageShowBox.vue';
import User from '@/model/user/User';
import UserID from '@/model/user/UserID';
import MessageEditBox from '@/components/post/MessageEditBox.vue';
import InputJpDate from '@/components/Parts/InputJpDate.vue';
import TypeDateTime from '@/model/type/TypeDateTime';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate'
]);
@Component({
  components: {
    BackButton,
    RequireMark,
    FileUpload,
    MessageEditBox,
    MessageShowBox,
    PostViewerSelector,
    InputJpDate
  }
})
export default class InformationPost extends Vue {
  postBody:PostBody = new PostBody();
  @State('loginUser') loginUser!:UserBaseInfo;
  @Getter('isAdminGroup') isAdminGroup!:boolean;
  // 閲覧可能者
  viewers: User[]=[];
  // 追加ファイル
  addingFiles:UploadFileContainer[]=[];
  // 保存済みファイル
  savedFileList:FileInfo[]=[];
  // 削除ファイル
  deleteList:string[]=[];
  // uploadファイルのカウンター v-for用
  sequence: number=0;
  editable = true;

  // 閲覧者ダイアログ用
  showDialog = false;
  users: User[] = [];

  // 登録処理実行中
  isRunning = false;

  // 上書き確認ダイアログ
  showRewriteDialog = false;

  // 画面遷移初期処理
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<InformationPost>): void {
    next((vm: InformationPost) => vm.initialize(to));
  }
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext<InformationPost>): void {
    next((vm: InformationPost) => vm.initialize(to));
  }

  // 初期処理
  initialize(to:Route): void {
    // IDが指定されていれば初期データ取得
    if (to.params.postId) {
      this.showPost(Number(to.params.postId));
      return;
    }
    // requestParam取得
    this.copyPost();
  }

  copyPost(): void {
    // eslint-disable-next-line no-restricted-globals
    const search = location.search as string;
    if (search.length === 0) {
      this.getLocalStorage();
      return;
    }
    const params = search.substring(1).split('&');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < params.length; i++) {
      const val = params[i].split('=');
      if (val[0] === 'copyfrom') {
        // 投稿本体取得
        InformationApi.getPostDetail(Number(val[1])).then((res) => {
          this.postBody.postTitle = res.data.body.postTitle;
          this.postBody.message.message = res.data.body.message.message;
        });
        return;
      }
    }
  }

  changeItem():void {
    const saveData = PostBody.clone(this.postBody);
    saveData.updateDate = TypeDateTime.now();
    if (this.$route.params.postId !== undefined) {
      localStorage.setItem(`posts${this.$route.params.postId}`, JSON.stringify(saveData));
      return;
    }
    localStorage.setItem('posts', JSON.stringify(saveData));
  }

  getLocalStorage():void{
    if (this.$route.params.postId !== undefined) {
      const postsData = localStorage.getItem(`posts${this.$route.params.postId}`);
      if (postsData === null) return;
      const chachedPostData = PostBody.clone(JSON.parse(postsData));
      if (this.postBody.updateDate.isAfterThan(chachedPostData.updateDate)) {
        // キャッシュで上書きするか確認
        this.showRewriteDialog = true;
      } else {
        this.postBody = PostBody.clone(JSON.parse(postsData));
      }
      return;
    }
    const postsData = localStorage.getItem('posts');
    if (postsData === null) return;
    this.postBody = PostBody.clone(JSON.parse(postsData));
  }

  // 強制的にキャッシュで上書き
  forceGetLocalStorage():void{
    if (this.$route.params.postId !== undefined) {
      const postsData = localStorage.getItem(`posts${this.$route.params.postId}`);
      if (postsData === null) return;
      this.postBody = PostBody.clone(JSON.parse(postsData));
    }
  }

  deleteLocalStorage():void{
    if (this.$route.params.postId !== undefined) {
      localStorage.removeItem(`posts${this.$route.params.postId}`);
      return;
    }
    localStorage.removeItem('posts');
  }

  // ファイル削除の記録
  deleteFile(idx:number): void {
    this.deleteList.push(this.savedFileList[idx].id.value);
    this.savedFileList.splice(idx, 1);
  }

  // ファイル追加
  addFile():void {
    // eslint-disable-next-line no-plusplus
    this.addingFiles.push({ seq: this.sequence++, file: null });
  }

  async showPost(postId: number):Promise<number> {
    // 投稿本体取得
    InformationApi.getPostDetail(postId).then((res) => {
      this.postBody = PostBody.clone(res.data.body);
      this.getLocalStorage();
    });
    // 添付ファイル取得
    InformationApi.getSavedFiles(postId).then((sfRes) => {
      this.savedFileList = FileInfo.clone(sfRes.data);
    });
    // 閲覧者取得
    InformationApi.getViewers(postId).then((res) => {
      this.viewers = res.data;
    });
    return 0;
  }

  viewerSelected(selectedUsers:User[]): void {
    // this.isRunning = true;
    this.showDialog = false;
    this.viewers = selectedUsers;
  }

  disableSave(): boolean {
    // 処理中は使用不可
    if (this.isRunning) return true;

    if (this.postBody.postTitle.value.length === 0) return true;
    if (this.postBody.message.message.value.length === 0) return true;

    if (!this.postBody.message.limitDate.value.isEmpty) {
      if (!this.postBody.message.limitDate.value.isValid) {
        return true;
      }
    }

    return false;
  }

  disableDraft():boolean {
    if (this.disableSave()) return true;
    if (this.postBody.status.isPublic()) return true;
    return false;
  }

  disablePost():boolean {
    if (this.disableSave()) return true;

    if (this.loginUser.priv.isAdminGroup) {
      // 管理者の場合閲覧者未選択なら投稿不可
      if (this.viewers.length === 0) return true;
    }
    return false;
  }

  /**
   * 下書き保存
   */
  postAsDraft():void {
    this.isRunning = true;
    this.postBody.status = DRAFT;
    this.postMain('下書きを保存しました。', []);
  }

  /**
   * 公開保存
   */
  post():void {
    let userIds: UserID[] = [];
    if (this.viewers.length > 0) {
      userIds = this.viewers.map((v) => v.id);
    }

    this.isRunning = true;
    this.postBody.status = PUBLIC;
    this.postMain('投稿しました。', userIds)
      .finally(() => {
        this.isRunning = false;
      });
  }

  /**
   * 投稿（保存）メイン処理
   */
  async postMain(completeMessage:string, userIds:UserID[]): Promise<string> {
    this.postBody.dataType = INFO;
    const res = await InformationApi.post(this.postBody, userIds).catch((err: ErrorEvent) => {
      this.$root.$emit('event-show-snackbar-infinity', '予期しないエラーが発生しました。');
      throw err;
    });
    const postId = res.data.value;

    // 添付ファイルをアップロード
    const data = this.createUploadData(postId);

    // ファイルアップロード
    await InformationApi.upload(data).catch((err:Error) => {
      this.$root.$emit('event-show-snackbar-infinity', 'ファイルアップロードに失敗しました。後程ファイルをアップロードし直してください。');
      throw err;
    });

    // 追加ファイルを削除
    this.addingFiles = [];

    this.deleteLocalStorage();

    // 登録完了
    this.$root.$emit('event-show-snackbar', completeMessage);

    // 登録終了後送信参照画面に遷移
    this.$router.push('/informationList');

    return '';
  }

  /**
   * アップロード用FormData作成
   */
  private createUploadData(postId: number): FormData {
    // ファイルアップロード
    const data = new FormData();
    data.append('postId', postId.toString());

    // 登録ファイル
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.addingFiles.length; i++) {
      const f = this.addingFiles[i].file;
      if (f) {
        data.append('postfiles', f);
      }
    }

    // 削除ファイル
    // eslint-disable-next-line no-plusplus
    for (let k = 0; k < this.deleteList.length; k++) {
      const h = this.deleteList[k];
      if (h) {
        data.append('deleteFiles', h);
      }
    }
    return data;
  }

  /**
   * 閲覧者選択ダイアログを表示
   */
  showSelectViewers():void {
    UserApi.getViewers().then((res) => {
      this.users = this.createViewers(User.cloneList(res.data), this.viewers);
      this.showDialog = true;
    });
  }

  /**
   * 閲覧者選択画面用ユーザー一覧作成
   * @param userList 表示対象のユーザー一覧
   * @param savedViewers 保存済みの閲覧可能者
   * @returns 選択画面用ユーザー一覧（現在閲覧可能チェック入り）
   */
  createViewers(userList: User[], savedViewers: User[]): User[] {
    // savedViewersに含まれるユーザーはuserListのcheckedをtrueにする
    savedViewers.forEach((su) => {
      const finded = userList.filter((u) => u.id.value === su.id.value);
      if (finded.length > 0) {
        finded[0].checked = true;
      }
    });
    return userList;
  }
}
