import { AxiosPromise } from 'axios';

import PostDetail from '@/model/posts/PostDetail';
import PostBody from '@/model/posts/PostBody';
import PostId from '@/model/posts/PostId';
import FileInfo from '@/model/type/FileInfo';
import UserID from '@/model/user/UserID';
import User from '@/model/user/User';
import CommentPost from '@/model/posts/comment/CommentPost';
import CommentId from '@/model/posts/comment/CommentId';
import PostReceiverReadStatus from '@/model/posts/PostReceiverReadStatus';
import axios from '../AxiosBase';

export default {
  /**
   * 情報投稿取得
   * @param postid
   * @returns
   */
  getPostDetail(postid:number):AxiosPromise<PostDetail> {
    return axios.get(`information/detail/${postid}`);
  },
  /**
   * 情報投稿を投稿
   * @param postBody
   * @param userIds
   * @returns
   */
  post(postBody: PostBody, userIds: UserID[]):AxiosPromise<PostId> {
    return axios.post('information/save', { body: postBody, viewers: userIds });
  },
  /**
   * 既読
   */
  readed(postId: PostId): AxiosPromise<void> {
    return axios.post(`information/readed/${postId.value}`);
  },
  /**
   * 添付ファイルアップロード
   * @param formData
   * @returns
   */
  upload(formData: FormData):AxiosPromise<string> {
    return axios.post('information/upload', formData);
  },
  /**
   * 保存済み添付ファイル取得
   * 同時に投稿を既読にする
   * @param postid
   * @returns
   */
  getSavedFiles(postid: number): AxiosPromise<FileInfo[]> {
    return axios.get(`information/files/${postid}`);
  },
  /**
   * 保存済みのコメント添付ファイル取得
   * 同時にコメントを既読にする
   * @param postId
   * @param commentId
   * @returns
   */
  getSavedCommentFiles(postId: number, commentId: number): AxiosPromise<FileInfo[]> {
    return axios.get(`information/commentFiles/${postId}/${commentId}`);
  },
  /**
   * 閲覧可能者取得
   * @param postid
   * @returns
   */
  getViewers(postid: number): AxiosPromise<User[]> {
    return axios.get(`information/viewers/${postid}`);
  },
  /**
   * コメント投稿
   * @param comment
   * @returns 投稿後のID
   */
  postComment(comment:CommentPost):AxiosPromise<PostId> {
    return axios.post('information/comment', comment);
  },
  /**
   * コメント下書き投稿
   * @param comment
   * @returns 投稿後のID
   */
  postCommentDraft(comment:CommentPost):AxiosPromise<PostId> {
    return axios.post('information/commentDraft', comment);
  },
  /**
   * コメント削除
   * @param commentId
   * @returns
   */
  deleteComment(commentId: CommentId): AxiosPromise<void> {
    return axios.post(`information/comment/delete/${commentId.value}`);
  },
  /**
   * 投稿削除
   */
  deletePost(postId: PostId): AxiosPromise<void> {
    return axios.post(`information/delete/${postId.value}`);
  },
  /**
   * @param commentId
   * @returns 閲覧者の既読、未読
   */
  commentReadStatus(commentId: CommentId): AxiosPromise<PostReceiverReadStatus> {
    return axios.post(`information/commentReadStatus/${commentId.value}`);
  }

};
