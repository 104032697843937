import moment from 'moment';

export default class TypeDateTime {
  value: string;
  dispString: string;
  dateYearMonth?: string;

  constructor(
    value?: string,
    dispString?: string,
    dateYearMonth?:string
  ) {
    this.value = (value === undefined ? '' : value);
    this.dispString = (dispString === undefined ? '' : dispString);
    this.dateYearMonth = (dateYearMonth === undefined ? '' : dateYearMonth);
  }

  isAfterThan(other: TypeDateTime): boolean {
    return moment(this.value).isAfter(moment(other.value));
  }

  static clone(source: TypeDateTime): TypeDateTime {
    const result = new TypeDateTime();
    result.value = source.value;
    result.dispString = source.dispString;
    result.dateYearMonth = source.dateYearMonth;
    return result;
  }

  static now(): TypeDateTime {
    const now = new TypeDateTime();
    now.value = moment().format('YYYY-MM-DDTHH:mm:ss');
    now.dispString = moment().format('YYYY/M/D HH:mm:ss');
    return now;
  }
}
