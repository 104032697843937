export default class FileId {
  value: string;

  constructor(value?: string) {
    this.value = (value === undefined ? '' : value);
  }
  public static clone(source:FileId):FileId {
    return new FileId(source.value);
  }
}
