export default class PostMessageBody {
  value: string;

  constructor(value?: string) {
    this.value = (value === undefined ? '' : value);
  }
  public static clone(source: PostMessageBody): PostMessageBody {
    return new PostMessageBody(source.value);
  }

  private countKeywords(keyword: string): number {
    return this.value.split(keyword).length - 1;
  }

  private isKeywordsEvenNumber(keyword: string): boolean {
    if (this.countKeywords(keyword) % 2 === 0) {
      return true;
    }
    return false;
  }

  private isContainKeywords(keyword: string): boolean {
    if (this.countKeywords(keyword) !== 0) {
      return true;
    }
    return false;
  }

  public deleteKeywords(keyword: string): string {
    if (this.isContainKeywords(keyword) && this.isKeywordsEvenNumber(keyword)) {
      return this.value.replaceAll(keyword, '');
    }
    return this.value;
  }
}

export const DECORATION_KEYWORDS_LENGTH = 2 as const;

export const DECORATION_KEYWORDS = {
  /** 赤字 */
  RED: '@'.repeat(DECORATION_KEYWORDS_LENGTH),
  /** 太字 */
  BOLD: '*'.repeat(DECORATION_KEYWORDS_LENGTH)
} as const;
