export default class PostStatus {
  value: number;

  constructor(value?: number) {
    this.value = (value === undefined ? -1 : value);
  }
  public static clone(source: PostStatus):PostStatus {
    return new PostStatus(source.value);
  }
  public isDraft():boolean {
    return this.value === 0;
  }
  public isPublic(): boolean {
    return this.value === 1;
  }
}
/** 下書き */
export const DRAFT = new PostStatus(0);
/** 公開 */
export const PUBLIC = new PostStatus(1);
/** 削除 */
export const DELETED = new PostStatus(2);
