import TypeJpDate from '@/model/type/TypeJpDate';

export default class PostLimitDate {
  value:TypeJpDate;

  constructor(
    value?:TypeJpDate
  ) {
    this.value = (value === undefined ? TypeJpDate.empty() : value);
  }

  static clone(source: PostLimitDate): PostLimitDate {
    return new PostLimitDate(TypeJpDate.clone(source.value));
  }
}
