
import {
  Component, Prop, Vue, Watch
} from 'vue-property-decorator';
import PostMessageBody, { DECORATION_KEYWORDS_LENGTH, DECORATION_KEYWORDS } from '@/model/posts/message/PostMessageBody';

@Component({})
export default class MessageEditBox extends Vue {
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) textAreaStyle!: any;
  innerValue: string = '';

  mounted():void {
    this.innerValue = this.value;
  }

  @Watch('value')
  watchValue(): void {
    this.innerValue = this.value;
  }

  @Watch('innerValue')
  emit():void {
    this.$emit('input', this.innerValue);
  }

  toRed():void {
    this.edit(DECORATION_KEYWORDS.RED);
  }
  toBold():void {
    this.edit(DECORATION_KEYWORDS.BOLD);
  }
  /**
   * 選択箇所にキーワードを挿入する
   */
  edit(keyword:string): void{
    const el: any = this.$refs.this_textarea;
    const st = el.selectionStart;
    const ed = el.selectionEnd;
    let edited = this.innerValue;

    if (st === ed) return;

    edited = `${edited.substring(0, st)
    }${keyword}${edited.substring(st, ed)
    }${keyword}${edited.substring(ed)}`;
    this.innerValue = edited;
    this.$nextTick(() => {
      // 選択状態を戻す
      el.focus();
      el.setSelectionRange(st, ed + DECORATION_KEYWORDS_LENGTH * 2);
    });
  }

  clearDecorations():void {
    const el: any = this.$refs.this_textarea;
    const st = el.selectionStart;
    const ed = el.selectionEnd;
    const innerString = this.innerValue;
    const selectionString = innerString.substring(st, ed);
    let selectionMessage = new PostMessageBody(selectionString);

    if (st === ed) return;

    selectionMessage = new PostMessageBody(selectionMessage.deleteKeywords(DECORATION_KEYWORDS.RED));
    selectionMessage = new PostMessageBody(selectionMessage.deleteKeywords(DECORATION_KEYWORDS.BOLD));

    this.innerValue = `${innerString.substring(0, st) + selectionMessage.value + innerString.substring(ed)}`;
    if (innerString === this.innerValue) {
      this.$nextTick(() => {
      // 削除しなかった場合、選択範囲戻す
        el.focus();
        el.setSelectionRange(st, ed);
      });
    }
  }

  clearAllDecorations():void {
    const el: any = this.$refs.this_textarea;
    const innerString = this.innerValue;
    let innerMessage = new PostMessageBody(innerString);

    innerMessage = new PostMessageBody(innerMessage.deleteKeywords(DECORATION_KEYWORDS.RED));
    innerMessage = new PostMessageBody(innerMessage.deleteKeywords(DECORATION_KEYWORDS.BOLD));

    this.innerValue = innerMessage.value;
  }

  changeItem():void{
    this.$emit('changeItem');
  }
}
