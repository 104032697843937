
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MessageShowBox extends Vue {
  @Prop() message!: string;
  @Prop({ default: false }) isPreview!: boolean;

  /**
   * プレビュー用にHTMLに編集
   */
  getHtml(): string {
    let edited = this.message.replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>');
    edited = edited.replace(/https?:\/\/([\S]+)[\S$]?/g, '<a&nbsp-a;href=$&&nbsp-a;target="_blank">$&</a>');
    edited = edited.replace(/ /g, '&nbsp;');
    edited = edited.replace(/&nbsp-a;/g, ' ');
    edited = edited.replace(/@@([^@]+)@@/g, '<span style="color:red">$1</span>');
    edited = edited.replace(/\n/g, '<br/>');

    return edited;
  }
}
