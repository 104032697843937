export default class PostTitle {
  value: string;

  constructor(value?: string) {
    this.value = (value === undefined ? '' : value);
  }
  public static clone(source:PostTitle):PostTitle {
    return new PostTitle(source.value);
  }
}
