export default class DataType {
  value: string;
  dispString: string;

  constructor(value?: string, dispString?: string) {
    this.value = (value === undefined ? '' : value);
    this.dispString = (dispString === undefined ? '' : dispString);
  }
  public static clone(source:DataType):DataType {
    return new DataType(source.value, source.dispString);
  }
}
export const INFO = new DataType('info', '情報投稿');
export const VACATION = new DataType('vacation', '休暇連絡');
export const COMMENT = new DataType('comment', 'コメント');
