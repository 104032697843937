import Priv from './Priv';
import UserID from './UserID';
import UserName from './UserName';
import UserNameKana from './UserNameKana';

export default class User {
  id: UserID;
  name: UserName;
  kana: UserNameKana;
  priv: Priv;
  /** 選択済み（Vue専用項目） */
  checked: boolean;

  constructor(
    id?: UserID,
    name?: UserName,
    kana?: UserNameKana,
    priv?: Priv
  ) {
    this.id = (id === undefined ? new UserID() : id);
    this.name = (name === undefined ? new UserName() : name);
    this.kana = (kana === undefined ? new UserNameKana() : kana);
    this.priv = (priv === undefined ? new Priv() : priv);
    this.checked = false;
  }

  static clone(source: User): User {
    const result = new User();
    result.id = UserID.clone(source.id);
    result.name = UserName.clone(source.name);
    result.kana = UserNameKana.clone(source.kana);
    result.priv = Priv.clone(source.priv);
    result.checked = false;
    return result;
  }
  static cloneList(source: User[]): User[] {
    const result: User[] = [];
    source.forEach((s) => {
      result.push(User.clone(s));
    });
    return result;
  }
}
