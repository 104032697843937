
import { Vue, Component, Prop } from 'vue-property-decorator';
import User from '@/model/user/User';

@Component({})
export default class PostViewerSelector extends Vue {
  // eslint-disable-next-line no-undef
  @Prop({ default: () => [] }) viewers!:User[];

  // ソートプロパティ名
  sortProp = 'kana';
  sortAsc = true;

  checkAll():void {
    // eslint-disable-next-line no-param-reassign
    this.viewers.forEach((v) => { v.checked = true; });
    // // eslint-disable-next-line no-restricted-syntax
    // for (const v of this.viewers) {
    //   v.checked = true;
    // }
    this.$nextTick();
  }
  unCheckAll():void {
    // eslint-disable-next-line no-param-reassign
    this.viewers.forEach((v) => { v.checked = false; });
    // // eslint-disable-next-line no-restricted-syntax
    // for (const v of this.viewers) {
    //   v.checked = false;
    // }
    this.$nextTick();
  }

  sort(newSortProp: string): void {
    if (this.sortProp === newSortProp) {
      // 昇順・降順を切替
      this.sortAsc = !this.sortAsc;
      return;
    }

    // 別の項目で昇順ソート
    this.sortProp = newSortProp;
    this.sortAsc = true;
  }

  showSort(prop: string): string {
    if (this.sortProp !== prop) return '';

    if (this.sortAsc) return '▲';
    return '▼';
  }

  get sorted():User[] {
    this.viewers.sort((a, b) => {
      if (this.getValue(a, this.sortProp) === this.getValue(b, this.sortProp)) return 0;
      if (this.getValue(a, this.sortProp) < this.getValue(b, this.sortProp)) return this.sortAsc ? -1 : 1;
      return !this.sortAsc ? -1 : 1;
    });
    return this.viewers;
  }

  getValue(user:User, prop:string): any {
    if (prop === 'checked') return user.checked;
    if (prop === 'id') return user.id.code;
    if (prop === 'kana') return user.kana.value;
    return '';
  }

  selected():void {
    const checkedUsers:User[] = [];
    this.viewers.forEach((v) => {
      if (v.checked) { checkedUsers.push(v); }
    });
    this.$emit('selected', checkedUsers);
  }
}
