import FileId from './FileId';
import FileName from './FileName';

export default class FileInfo {
  /** レポート添付ファイル */
  id: FileId;
  /** 元ファイル */
  name: FileName;
  /** URL */
  url: string;

  constructor(
    id?: FileId,
    name?: FileId,
    url?: string
  ) {
    this.id = (id === undefined ? new FileId() : id);
    this.name = (name === undefined ? new FileName() : name);
    this.url = (url === undefined ? '' : url);
  }

  /**
   * ファイルがPDFかどうか
   */
  public isPdf(): boolean {
    if (this.id.value.toLowerCase().match(/\.(PDF)$/i)) {
      return true;
    }
    return false;
  }

  /**
   * サーバから取得したオブジェクトを再生成することでクラスとして使用できるようにする
   * （インスタンスメソッドを使えるようにする）
   * @param origin
   * @returns
   */
  public static clone(origin :FileInfo[]):FileInfo[] {
    const result:FileInfo[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < origin.length; i++) {
      result.push(new FileInfo(origin[i].id, origin[i].name, origin[i].url));
    }
    return result;
  }

  public static create():FileInfo[] {
    const item = new FileInfo();
    const result:FileInfo[] = [];
    result.push(item);
    return result;
  }
}
